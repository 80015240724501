import React from 'react'
import {graphql} from 'gatsby'
import Img, {FluidObject} from 'gatsby-image'
import styled, {css} from 'styled-components'
import {useAuth, useUser, useAmplitude} from 'hooks'
import {MainLayout} from 'layouts'
import {SEO, AviQuote, LinkButton, AnchorButton} from 'components'
import {PersonStudent, CreatorLock} from 'images/svgs'
import PathwayIconBkg from 'images/pathway-icon-bkg.svg'

const EyebrowText = styled.p`
  margin: 0 !important;

  font-family: var(--title-font);
  font-weight: bold;
  color: var(--blue);
`

const SectionTitleDiv = styled.div<{align?: 'left' | 'center' | 'right'}>`
  margin: 25px 0;
  text-align: ${({align}) => align ?? 'center'};

  h1,
  h2 {
    margin: 0;
  }

  @media only screen and (min-width: 700px) {
    max-width: var(--reading-width);
  }
`

const BasicSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`

const BasicContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--mobile-width);

  @media only screen and (min-width: 700px) {
    max-width: var(--content-width);
  }
`

const IntroSection = styled(BasicSection)`
  padding-bottom: 50px;

  background-color: var(--white);
`

const IntroContent = styled(BasicContent)`
  flex-direction: column-reverse;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
    max-width: var(--reading-width);
  }
`

const TitleDiv = styled.div`
  h1 {
    margin-top: 0;
  }
`

const TagsRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
`

const tag = css`
  display: inline-block;

  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 10px;
  margin-left: 0px;

  font-family: var(--primary-font);
  font-size: 0.9em;
  font-weight: 600;
`

const TechTag = styled.div`
  ${tag}
  background-color: var(--violet);
`

const LevelTag = styled.div`
  ${tag}
  background-color: var(--light-grey);
  align-self: flex-start;
`

const Thumbnail = styled(Img)<{fluid: FluidObject}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 300px;
  height: 300px;

  background: center / contain no-repeat url(${PathwayIconBkg});
`

const DetailsSection = styled(BasicSection)`
  background-color: var(--light-grey);
`

const DetailsContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  margin: 50px 0;

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: unset;

    margin: 25px 0;
  }
`

const Detail = styled.div`
  text-align: center;

  h6 {
    margin: 0;
  }

  p {
    margin-bottom: 0;
  }
`

const ContentSection = styled(BasicSection)`
  padding: 50px;
`

const ContentContent = styled(BasicContent)``

const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;
  padding: 20px;

  border-radius: 10px;
  background-color: var(--white);

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    max-width: var(--content-width);
  }
`

const ContentCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    margin-right: 20px;
    max-width: var(--reading-width);

    h6 {
      margin: 0;
    }
  }
`

const ContentIcon = styled(Img)<{fluid: FluidObject}>`
  width: 125px;
  height: 100px;

  @media only screen and (min-width: 700px) {
    flex-shrink: 0;
    margin-right: 20px;
  }
`

const TitleSection = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
`

const StyledLock = styled(CreatorLock)`
  height: 25px;
  margin-right: 16px;
  margin-bottom: 4px;
`

const PersonaSection = styled(BasicSection)`
  background-color: var(--white);

  padding: 50px 0;
`

const PersonaContent = styled(BasicContent)`
  flex-direction: column-reverse;

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    max-width: var(--reading-width);
  }
`

const Traits = styled.div``

const PersonaTrait = styled.p`
  border-radius: 5px;
  padding: 16px;
  background-color: var(--light-blue);
  font-weight: 600;
`

const StyledStudent = styled(PersonStudent)`
  flex-shrink: 0;
  width: 250px;
`

const CertificateSection = styled(BasicSection)`
  background-color: var(--white);

  padding: 25px 0 50px;
`

const CertificateContent = styled(BasicContent)`
  @media only screen and (min-width: 700px) {
    flex-direction: row;
    max-width: var(--reading-width);
  }
`

const CertificateText = styled.div`
  padding-right: 20px;

  p {
    margin-bottom: 32px;
  }
`

const CertificatePreview = styled(Img)<{fluid: FluidObject}>`
  @media only screen and (min-width: 700px) {
    width: 300px;
    flex-shrink: 0;
  }
`

const PathwayPage = (props: {data: PathwayDetailsQuery}) => {
  const {user} = useAuth()
  // const {user: userData, projects} = useUser()
  const {logEvent} = useAmplitude()
  const {strapiPathway, certificate, allStrapiProject, allStrapiTopic} = props.data

  console.log(strapiPathway)

  const logClick = (buttonName, data = {}) =>
    logEvent('pathway-view-button-click', {slug: strapiPathway.slug, buttonName, ...data})

  return (
    <MainLayout>
      <SEO title={strapiPathway.title} description={strapiPathway.description} />
      <IntroSection>
        <IntroContent>
          <TitleDiv>
            <EyebrowText>PATHWAY</EyebrowText>
            <h1>{strapiPathway.title}</h1>
            <p>{strapiPathway.description}</p>
            <TagsRow>
              {strapiPathway.categories &&
                strapiPathway.categories.length !== 0 &&
                strapiPathway.categories.map(({tag}, index) => <TechTag key={index}>{tag}</TechTag>)}
            </TagsRow>
          </TitleDiv>
          <Thumbnail
            imgStyle={{
              objectFit: 'contain',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '70%',
              height: '70%',
            }}
            fluid={strapiPathway.gatsbyThumbnail.childImageSharp.fluid}
          />
        </IntroContent>
      </IntroSection>
      <DetailsSection>
        <DetailsContent>
          <Detail>
            <h6>LEVEL</h6>
            <p>{strapiPathway.level}</p>
          </Detail>
          <Detail>
            <h6>DELIVERABLES</h6>
            <p>{strapiPathway.deliverables}</p>
          </Detail>
          <Detail>
            <h6>ON COMPLETION</h6>
            <p>Get Certified</p>
          </Detail>
        </DetailsContent>
      </DetailsSection>
      <ContentSection>
        <ContentContent>
          <SectionTitleDiv>
            <EyebrowText>CURRICULUM</EyebrowText>
            <h2>What to Take</h2>
          </SectionTitleDiv>
          {strapiPathway.content.map((content, i) => {
            switch (content.contentType) {
              case 'project':
                const project = allStrapiProject.nodes.find((p) => p.slug === content.project.slug)

                if (project == null) return <></>

                return (
                  <ContentCard key={i}>
                    <ContentCardContent>
                      <ContentIcon imgStyle={{objectFit: 'contain'}} fluid={project.gatsbyIcon.childImageSharp.fluid} />
                      <div>
                        <TitleSection>
                          {!project.free && <StyledLock />}
                          <h6>
                            {i + 1}. {project.title}
                          </h6>
                        </TitleSection>
                        <p>{project.description}</p>
                      </div>
                    </ContentCardContent>
                    <LinkButton
                      tier='primary'
                      to={`/projects/${project.slug}`}
                      onClick={() => logClick('task-preview', {type: 'project', slug: project.slug})}
                    >
                      View Project
                    </LinkButton>
                  </ContentCard>
                )
              case 'topic':
                const topic = allStrapiTopic.nodes.find((t) => t.slug === content.topic.slug)

                if (topic == null) return <></>

                return (
                  <ContentCard key={i}>
                    <ContentCardContent>
                      <ContentIcon imgStyle={{objectFit: 'contain'}} fluid={topic.gatsbyIcon.childImageSharp.fluid} />
                      <div>
                        <TitleSection>
                          {!topic.free && <StyledLock />}
                          <h6>
                            {i + 1}. {topic.title}
                          </h6>
                        </TitleSection>
                        <p>{topic.description}</p>
                      </div>
                    </ContentCardContent>
                    <LinkButton
                      tier='primary'
                      to={`/topics/${topic.slug}`}
                      onClick={() => logClick('task-preview', {type: 'topic', slug: topic.slug})}
                    >
                      View Topic
                    </LinkButton>
                  </ContentCard>
                )
            }
          })}
        </ContentContent>
      </ContentSection>
      <PersonaSection>
        <BasicContent>
          <SectionTitleDiv>
            <EyebrowText>WHO IS THIS FOR?</EyebrowText>
            <h2>This Pathway Is Perfect for You If...</h2>
          </SectionTitleDiv>
        </BasicContent>
        <PersonaContent>
          <Traits>
            {strapiPathway.traits.map(({trait}, i) => (
              <PersonaTrait key={i}>{trait}</PersonaTrait>
            ))}
          </Traits>
          <StyledStudent />
        </PersonaContent>
      </PersonaSection>
      <CertificateSection>
        <CertificateContent>
          <CertificateText>
            <SectionTitleDiv align='left'>
              <EyebrowText>CERTIFICATE</EyebrowText>
              <h2>Finished This Pathway?</h2>
            </SectionTitleDiv>
            <p>
              After you have completed all topics and projects within the pathway, request to get a certificate using
              the form below.
            </p>
            <AnchorButton
              tier='primary'
              href='https://airtable.com/shrdI7WtjsqsXnmL1'
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => logClick('get-certified')}
            >
              Get Certified
            </AnchorButton>
          </CertificateText>
          <CertificatePreview fluid={certificate.childImageSharp.fluid} />
        </CertificateContent>
      </CertificateSection>
      {user === null && <AviQuote />}
    </MainLayout>
  )
}

export default PathwayPage

type GatsbyImageSharpFluid = {
  childImageSharp: {
    fluid: FluidObject
  }
}

interface StrapiPathway {
  slug: string
  title: string
  description: string
  deliverables: string
  level: string
  categories: Array<{tag: string}>
  gatsbyThumbnail: GatsbyImageSharpFluid
  traits: Array<{
    trait: string
  }>
  content: Array<{
    contentType: 'project' | 'topic'
    project: {
      slug: string
    }
    topic: {
      slug: string
    }
  }>
}

interface StrapiProject {
  slug: string
  title: string
  description: string
  free: boolean
  gatsbyIcon: GatsbyImageSharpFluid
}

interface StrapiTopic {
  slug: string
  title: string
  description: string
  free: boolean
  gatsbyIcon: GatsbyImageSharpFluid
}

interface PathwayDetailsQuery {
  certificate: GatsbyImageSharpFluid
  strapiPathway: StrapiPathway
  allStrapiProject: {nodes: Array<StrapiProject>}
  allStrapiTopic: {nodes: Array<StrapiTopic>}
}

export const PathwayDetails = graphql`
  query PathwayDetails($slug: String, $projectSlugs: [String], $topicSlugs: [String]) {
    certificate: file(relativePath: {eq: "certificate.webp"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    strapiPathway(slug: {eq: $slug}) {
      slug
      title
      description
      deliverables
      level
      categories {
        tag
      }
      gatsbyThumbnail {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      traits {
        trait
      }
      content {
        contentType
        project {
          slug
        }
        topic {
          slug
        }
      }
    }
    allStrapiProject(filter: {slug: {in: $projectSlugs}}) {
      nodes {
        slug
        title
        description
        free
        gatsbyIcon {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiTopic(filter: {slug: {in: $topicSlugs}}) {
      nodes {
        slug
        title
        description
        free
        gatsbyIcon {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
